import { singletonHook } from 'react-singleton-hook';
import { useCallback, useEffect, useState } from 'react';

const useFacebook = () => {
  const [loginStatus, setLoginStatus] = useState<Record<string, any> | null>(
    null,
  );

  useEffect(() => {
    (() => {
      // @ts-ignore
      if (typeof window?.FB === 'undefined') {
        return;
      }
      // @ts-ignore
      FB.init({
        appId: process.env.NEXT_PUBLIC_META_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v17.0',
      });
      // @ts-ignore
      window.FB.getLoginStatus((loginStatus: any) => {
        console.log(
          'https://developers.facebook.com/docs/facebook-login/web',
          loginStatus,
        );
        if (loginStatus?.status === 'connected' && loginStatus?.authResponse) {
          setLoginStatus(loginStatus?.authResponse);
          return;
        }
      });
    })();
  }, []);

  const connect = useCallback(() => {
    // @ts-ignore
    if (typeof window?.FB === 'undefined') {
      return;
    }
    try {
      // @ts-ignore
      window.FB.getLoginStatus((loginStatus: any) => {
        if (loginStatus?.status === 'connected' && loginStatus?.authResponse) {
          setLoginStatus(loginStatus?.authResponse);
          return;
        }
        // @ts-ignore
        window.FB.login(
          (e: any) => {
            setLoginStatus(e?.authResponse);
          },
          {
            config_id: process.env.NEXT_PUBLIC_META_APP_CONFIG_ID,
            response_type: 'code',
          },
        );
      });
    } catch (e) {
      console.error(e);
    }
  }, []);
  const disconnect = useCallback(() => {
    setLoginStatus(null);
    // @ts-ignore
    if (typeof window?.FB === 'undefined') {
      return;
    }
    // @ts-ignore
    window.FB.logout();
  }, []);

  const facebookAPI = useCallback(
    (url: string, method?: string, params?: Record<string, any>) => {
      return new Promise<any>((resolve, reject) => {
        try {
          // @ts-ignore
          if (typeof window?.FB === 'undefined') {
            reject();
            return;
          }
          // @ts-ignore
          window.FB.api(url, method, params, (res: any) => {
            resolve(res);
          });
        } catch (e) {
          reject(e);
        }
      });
    },
    [],
  );

  const getPublishingLimit = useCallback(async (id) => {
    return facebookAPI(`${id}/content_publishing_limit`);
  }, []);

  return {
    loginStatus,
    connect,
    disconnect,
    getPublishingLimit,
    facebookAPI,
  };
};

export default singletonHook(
  {
    loginStatus: null,
    connect: () => {},
    disconnect: () => {},
    getPublishingLimit: async () => {},
    facebookAPI: async () => {},
  },
  useFacebook,
);
