import create from 'zustand';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { useRouter } from 'next/router';
import AuthContext from '@simplecy/frontend-types/components/context/AuthContext';

export type State = {
  status: string | null;
  accessToken: string | null;
  refreshToken: string | null;
};

const defaultState: State = {
  status: null,
  accessToken: null,
  refreshToken: null,
};

const getCookieSession = () => {
  if (typeof window !== 'undefined') {
    const cookies = parseCookies();
    if (cookies?.ssoSession) {
      try {
        return JSON.parse(window.atob(cookies.ssoSession));
      } catch (e) {
        return defaultState;
      }
    }
  }
  return defaultState;
};

export const useAuthState = create<State>(() => {
  return getCookieSession();
});

export const logout = async () => await useAuthState.setState(defaultState);

export const userIsLoggedIn = () => {
  const authState = useAuthState.getState();
  return !!authState?.accessToken;
};

export const useAuthenticator = () => {
  const router = useRouter();

  const [ready, setReady] = useState<boolean>(false);
  const [state, setState] = useState(useAuthState.getState());

  const loggedIn = useMemo(() => {
    return !!state?.accessToken;
  }, [state?.accessToken]);

  useEffect(() => {
    useAuthState.subscribe((event) => {
      destroyCookie(null, 'ssoSession');
      if (event?.status?.toUpperCase() === 'OK') {
        setCookie(null, 'ssoSession', window.btoa(JSON.stringify(event)));
      }
      setState(event);
    });
    return () => {
      useAuthState.destroy();
    };
  }, []);

  useEffect(() => {
    (async () => {
      await Promise.resolve();
      setReady(true);
    })();
  }, [!!state?.accessToken]);

  const updateState = useCallback(
    async (
      event: Partial<State>,
    ): Promise<{
      token: State;
    }> => {
      const data = { ...useAuthState.getState(), ...event };
      await useAuthState.setState(data);
      return {
        token: data,
      };
    },
    [state],
  );

  return {
    ready,
    state,
    loggedIn,
    update: updateState,
    logout,
  };
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    console.error(
      '[@simplecy/frontend-admin] useAuth(): No AuthContext found\nAdd it to _app.tsx',
    );
  }
  return context;
};

export default useAuth;
