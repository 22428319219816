import { getKy } from '@simplecy/frontend-types/utils/ky';
import { useAuthState } from '@hooks/useAuth';

export const nonAuthenticatedKy = getKy({
  refreshTokenEndpoint: 'auth/refresh-admin-token',
  authState: useAuthState,
  authenticated: false,
  timeout: false,
});

export default getKy({
  refreshTokenEndpoint: 'auth/refresh-admin-token',
  authState: useAuthState,
  timeout: false,
});
