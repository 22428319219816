'use client';

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import uniqBy from 'lodash.uniqby';
import useFacebook from '@hooks/useFacebook';
import { toast } from '@simplecy/frontend-ui/src/utils/toast';

const BROKER_START_DELAY = 200;

const STORAGE_KEY = 'ead7279a-5cc7-42d0-b1aa-70dc9987c59a';

const PUBLISH_AFTER_CONTAINER_FINISHED_ATTEMPT_INTERVAL = 3000;
const PUBLISH_AFTER_CONTAINER_FINISHED_ATTEMPT_LIMIT = 50;

const CREATE_AND_PUBLISH_CAROUSEL_ATTEMPT_INTERVAL = 5000;
const CREATE_AND_PUBLISH_CAROUSEL_ATTEMPT_LIMIT = 50;

const ToastMessages = {
  JOB_COMPLETED: 'Instagram publish job successfully completed',
  ATTEMPT_LIMIT_EXCEED: 'Instagram publish job attempt limit exceed',
  INVALID_LOGIN_STATUS:
    'Invalid Instagram login status. Unable to proceed publish job',
};

export enum MetaQueueJobType {
  'PUBLISH_AFTER_CONTAINER_FINISHED',
  'CREATE_AND_PUBLISH_CAROUSEL',
}

type QueueJob =
  | {
      id: string;
      type: MetaQueueJobType.PUBLISH_AFTER_CONTAINER_FINISHED;
      data: {
        containerId: string;
        instagramBusinessAccountId: string;
      };
    }
  | {
      id: string;
      type: MetaQueueJobType.CREATE_AND_PUBLISH_CAROUSEL;
      data: {
        containerIds: string[];
        instagramBusinessAccountId: string;
        caption: string;
      };
    };

const defaultQueue: QueueJob[] = (() => {
  try {
    return JSON.parse(window?.localStorage?.getItem(STORAGE_KEY) ?? '') ?? [];
  } catch (e) {
    console.error(e);
    return [];
  }
})();

const useMetaBroker = () => {
  const { loginStatus, facebookAPI } = useFacebook();

  const [queue, setQueue] = useState<QueueJob[]>(defaultQueue);
  const addToQueue = useCallback((e: Omit<QueueJob, 'id'>) => {
    const id = String(new Date().getTime());
    // @ts-ignore
    setQueue((q) => uniqBy([...q, { id, ...e }], 'id'));
  }, []);
  useEffect(() => {
    window?.localStorage?.setItem(STORAGE_KEY, JSON.stringify(queue));
  }, [queue]);

  const [timersById, setTimersById] = useState<Record<string, any>>({});
  useEffect(() => {
    Object.keys(timersById).forEach((key) => {
      if (!queue.find((q) => q.id === key)) {
        clearInterval(timersById[key]);
        setTimersById((e) => {
          delete e[key];
          return e;
        });
      }
    });
  }, [queue]);

  const handleQueue = useCallback(
    (e: QueueJob) => {
      if (timersById[e.id]) {
        return;
      }
      switch (e.type) {
        case MetaQueueJobType.PUBLISH_AFTER_CONTAINER_FINISHED: {
          let a = 1;
          const i = setInterval(async () => {
            const res = await facebookAPI(
              `${e.data.containerId}?fields=status_code`,
            );
            if (res?.status_code === 'FINISHED') {
              clearInterval(i);
              await facebookAPI(
                `${e.data.instagramBusinessAccountId}/media_publish`,
                'POST',
                {
                  creation_id: e.data.containerId,
                },
              );
              setQueue((q) => q.filter((qe) => qe.id !== e.id));
              toast.success(ToastMessages.JOB_COMPLETED);
              return;
            }
            if (res?.status_code === 'PUBLISHED') {
              clearInterval(i);
              setQueue((q) => q.filter((qe) => qe.id !== e.id));
              return;
            }
            if (res?.status_code === 'ERROR') {
              clearInterval(i);
              setQueue((q) => q.filter((qe) => qe.id !== e.id));
              if (res?.status) {
                toast.error(res.status);
              }
              return;
            }
            if (a >= PUBLISH_AFTER_CONTAINER_FINISHED_ATTEMPT_LIMIT) {
              clearInterval(i);
              setQueue((q) => q.filter((qe) => qe.id !== e.id));
              toast.error(ToastMessages.ATTEMPT_LIMIT_EXCEED);
              return;
            }
            a += 1;
          }, PUBLISH_AFTER_CONTAINER_FINISHED_ATTEMPT_INTERVAL);
          setTimersById((s) => ({ ...s, [e.id]: i }));
          return;
        }
        case MetaQueueJobType.CREATE_AND_PUBLISH_CAROUSEL: {
          let a = 1;
          const i = setInterval(async () => {
            // TODO Add FINISHED ids cache to avoid unnecessary requests
            const responses = await Promise.all(
              e.data.containerIds.map(async (id) => {
                return facebookAPI(`${id}?fields=status,status_code`);
              }),
            );
            if (
              responses.every((res: any) => res?.status_code === 'FINISHED')
            ) {
              clearInterval(i);
              setQueue((q) => q.filter((qe) => qe.id !== e.id));
              const containerId = (
                await facebookAPI(
                  `${e.data.instagramBusinessAccountId}/media`,
                  'POST',
                  {
                    children: e.data.containerIds,
                    media_type: 'CAROUSEL',
                    caption: e.data.caption,
                  },
                )
              )?.id;
              addToQueue({
                type: MetaQueueJobType.PUBLISH_AFTER_CONTAINER_FINISHED,
                data: {
                  containerId,
                  instagramBusinessAccountId: e.data.instagramBusinessAccountId,
                },
              });
              return;
            }
            if (responses.some((res: any) => res?.status_code === 'ERROR')) {
              clearInterval(i);
              setQueue((q) => q.filter((qe) => qe.id !== e.id));
              responses
                .filter((res: any) => res?.status_code === 'ERROR')
                .forEach((res: any) => {
                  if (res?.status) {
                    toast.error(res.status);
                  }
                });
              return;
            }
            if (
              responses.some((res: any) => res?.status_code === 'PUBLISHED')
            ) {
              clearInterval(i);
              setQueue((q) => q.filter((qe) => qe.id !== e.id));
              return;
            }
            if (a >= CREATE_AND_PUBLISH_CAROUSEL_ATTEMPT_LIMIT) {
              clearInterval(i);
              setQueue((q) => q.filter((qe) => qe.id !== e.id));
              toast.error(ToastMessages.ATTEMPT_LIMIT_EXCEED);
              return;
            }
            a += 1;
          }, CREATE_AND_PUBLISH_CAROUSEL_ATTEMPT_INTERVAL);
          setTimersById((s) => ({ ...s, [e.id]: i }));
          return;
        }
        default:
      }
    },
    [facebookAPI],
  );

  // Broker init logic
  const [delayed, setDelayed] = useState<boolean>(true);
  useEffect(() => {
    setTimeout(() => {
      setDelayed(false);
    }, BROKER_START_DELAY);
  }, []);
  useEffect(() => {
    (() => {
      setTimeout(() => {
        if (delayed) {
          return;
        }
        if (!loginStatus) {
          if (queue.length) {
            toast.error(ToastMessages.INVALID_LOGIN_STATUS);
          }
          return;
        }
        queue.forEach((q) => {
          handleQueue(q);
        });
      }, BROKER_START_DELAY);
    })();
  }, [delayed, loginStatus, queue]);

  return {
    queue,
    setQueue,
    addToQueue,
  };
};

export const MetaBrokerContext = createContext<
  ReturnType<typeof useMetaBroker>
>({
  queue: [],
  setQueue: () => {},
  addToQueue: () => {},
});

export const useMetaBrokerContext = () => useContext(MetaBrokerContext);

export default useMetaBroker;
