import '../styles/main.scss';
import { AppContext, AppInitialProps, AppLayoutProps } from 'next/app';
import type { NextComponentType } from 'next';
import { ReactNode } from 'react';
import Head from 'next/head';
import { useAuthenticator } from '@hooks/useAuth';
import AuthContext from '@simplecy/frontend-types/components/context/AuthContext';
import {
  // useOnlineToast,
  useRouterProgress,
} from '@simplecy/frontend-ui';
import Toaster from '@simplecy/frontend-ui/src/components/Toaster';
import ky from '@utils/ky';
import { SWRConfig } from 'swr';
import Waiter from '@simplecy/frontend-ui/src/hooks/useWait/Waiter';
import NiceModal from '@simplecy/frontend-ui/src/hooks/useNiceModal/context';
import ChatGPT from '@components/core/ChatGPT/ChatGPT';
import useMetaBroker, { MetaBrokerContext } from '@hooks/useMetaBroker';

const getAppHeightWidth = () => {
  if (typeof document !== 'undefined') {
    const doc = document.documentElement;
    doc.style.setProperty('--dvh', `${window.innerHeight}px`);
    doc.style.setProperty('--dvw', `${window.innerWidth}px`);
  }
};
if (typeof window !== 'undefined') {
  window.addEventListener('resize', getAppHeightWidth);
  getAppHeightWidth();
}

const MyApp: NextComponentType<AppContext, AppInitialProps, AppLayoutProps> = ({
  Component,
  pageProps,
}: AppLayoutProps) => {
  const getLayout = Component.getLayout || ((page: ReactNode) => page);
  // useOnlineToast();
  useRouterProgress();
  const authenticator = useAuthenticator();
  const metaBrokerCtx = useMetaBroker();

  return (
    <>
      <Head>
        <title>Simple Around</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link
          rel="manifest"
          href="/site.webmanifest"
        />
        <link
          rel="mask-icon"
          href="/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <meta
          name="msapplication-TileColor"
          content="#da532c"
        />
        <meta
          name="theme-color"
          content="#ffffff"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          revalidateOnReconnect: true,
          fetcher: async (...args: any[]) => {
            try {
              return ky.get(args[0]).json();
            } catch (e) {
              console.error(e);
            }
          },
        }}
      >
        {authenticator.ready && (
          <AuthContext.Provider value={authenticator}>
            <NiceModal.Provider>
              <ChatGPT.Provider>
                <Waiter>
                  <MetaBrokerContext.Provider value={metaBrokerCtx}>
                    <Toaster />
                    {getLayout(<Component {...pageProps} />)}
                  </MetaBrokerContext.Provider>
                </Waiter>
              </ChatGPT.Provider>
            </NiceModal.Provider>
          </AuthContext.Provider>
        )}
      </SWRConfig>
      {/* eslint-disable-next-line @next/next/no-sync-scripts */}
      <script
        crossOrigin="anonymous"
        src="https://connect.facebook.net/en_US/sdk.js"
      />
    </>
  );
};

export default MyApp;
