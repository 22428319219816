import { createContext, useCallback, useState } from 'react';
import { Message } from '@hooks/useChatGPT';
import uniqid from 'uniqid';
import ky from '@utils/ky';

export const ChatGPTProviderContext = createContext<{
  chatHistory: Message[];
  prompt: (e: string, waitAnswer?: boolean) => Promise<any>;
  answerQueue: string[];
}>({
  chatHistory: [],
  prompt: async () => {
    console.warn('<ChatGPTProviderContext.Provider> not found');
  },
  answerQueue: [],
});

const ChatGPT = ({ children }: { children: any }) => {
  const [chatHistory, setChatHistory] = useState<Message[]>([]);
  const [answerQueue, setAnswerQueue] = useState<string[]>([]);

  const pushMessage = useCallback((e: Message) => {
    setChatHistory((s) => [...s, e]);
  }, []);
  const prompt = useCallback(async (e: string, waitAnswer: boolean = true) => {
    const id = uniqid();
    pushMessage({
      id,
      text: e,
      timestamp: new Date(),
    });
    if (!waitAnswer) {
      return;
    }
    try {
      setAnswerQueue((s) => [...s, id]);
      const response = await ky
        .post('chatgpt/talk', { json: { message: e } })
        .json();
      pushMessage({
        id: uniqid(),
        parentId: id,
        text: (response as any)?.text ?? '',
        timestamp: new Date(),
      });
      return response;
    } catch (e) {
      console.error(e);
    } finally {
      setAnswerQueue((s) => s.filter((aq) => aq !== id));
    }
  }, []);

  return (
    <ChatGPTProviderContext.Provider
      value={{
        chatHistory,
        prompt,
        answerQueue,
      }}
    >
      {children}
    </ChatGPTProviderContext.Provider>
  );
};

export default {
  Provider: ChatGPT,
};
